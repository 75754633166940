<template>
  <div>
    <b-modal
      ref="modal-create-event"
      centered
      size="lg"
      
      @hide="((e)=>{ if (modalInfo.loading) e.preventDefault()})"
      @shown="modalInfo.modalOpen=true"
      lazy
      static
    > 
    
    <template #modal-title>
      <div>
        Creating Event
      </div>
    </template>

      <div @keydown.enter="attemptCreateEvent()">

        <div class="mb-1" >
          <label class="font-weight-bold" >Event name</label>
          <b-form-input autofocus placeholder="My new Event..." :state="checkValidation('newEventName')" @input="dataCreateEventValid()" v-model="modalInfo.newEventName" />
        </div>

        <div class="mb-1 d-flex justify-content-between">
          <div class="w-50">
            <label class="font-weight-bold" >Retry interval

            </label>
            <v-select
              :clearable="false"
              :options="getRetryLimits()"
              placeholder="time between retries"
              v-model="modalInfo.newEventData.retryLimitItem"
            >
              <template #option="data">
                {{$t(data.label)}}
              </template>

              <template #selected-option="data">
                {{$t(data.label)}}
              </template>


            </v-select>
          </div>

            <div class="w-50" align="right">
              <div class="w-75" align="left">
                <b-collapse :visible="modalInfo.newEventData.retryLimitItem && modalInfo.newEventData.retryLimitItem.id != 1">
                  <label class="font-weight-bold" >Max number of attempts</label>
                  <b-form-input @input="dataCreateEventValid()" type="number" :state="checkValidation('maxRetry')" v-model="modalInfo.newEventData.maxRetry"></b-form-input>
              </b-collapse>

              </div>
            </div>
        </div>



      <b-collapse appear :visible="true" v-if="modalInfo.modalOpen" >
        <label class="font-weight-bold">JSON Body</label>
        <div class="border">
          <monaco
            @destroyed="modalInfo.modalOpen=false"
            language="json"
            v-model="modalInfo.newEventData.object"
            :updateInterval="200"
          />
        </div>
      </b-collapse>

      <!-- 
          newEventData:{
            identifier: '',
            retryLimitItem: 2,
            maxRetry: 1,
            object: {}
          }
      -->

    </div>

    <template #modal-footer>
      <div>
        <b-button :disabled="modalInfo.loading" variant="success" @click="attemptCreateEvent()">
          <span v-if="!modalInfo.loading">Submit</span>
          <b-spinner small v-else />
        </b-button>
      </div>
    </template>

  </b-modal>

</div>
</template>

<script>
import { 
  BCol,
  BRow, 
  BButton, 
  BButtonGroup, 
  BSkeleton, 
  BModal, 
  BSpinner, 
  BFormInput, 
  BBadge, 
  BCollapse 
} from "bootstrap-vue";
import vSelect from "vue-select";
import Monaco from "@/layouts/components/editor/monaco.vue";

import { makeToast } from "@/layouts/components/Popups";

import EnumRetryLimits from "@/custom/class/Enum/RetryLimits";


  export default {  
    components: {
      BCol,
      BRow, 
      BButton, 
      BButtonGroup, 
      BSkeleton, 
      BModal, 
      BSpinner, 
      BFormInput, 
      BBadge, 
      BCollapse,
      vSelect,
      Monaco,
    },
    data() {
      return {
        modalInfo:{
          modalOpen: false,
          loading: false,
          newEventName: "",
          newEventData:{
            retryLimitItem: this.getRetryLimits()[0],
            maxRetry: 1,
            object: `{\n    \n}`,
          },
          invalidFields: null
        },
      };
    },
    methods: {    
      show(){
        this.$refs['modal-create-event'].show()
      },
      dataCreateEventValid(){
        this.modalInfo.invalidFields = []
        let status = true;
        
        if ( (!this.modalInfo.newEventName) || (!this.modalInfo.newEventName.length)){
          this.modalInfo.invalidFields.push('newEventName')
          status = false
        }

        if (this.modalInfo.newEventData.maxRetry <= 0){
          this.modalInfo.invalidFields.push('maxRetry')
          status = false
        }

        return status
      },
    checkValidation(field){
      if (!this.modalInfo.invalidFields){
        return null
      }

      if (this.modalInfo.invalidFields.includes(field)){
        return false
      } else {
        return true
      }
    },
    attemptCreateEvent(){      
      if (!this.dataCreateEventValid()){
        makeToast({
          title: "Can't submit yet",
          text: "Please fill in the remaining fields",
          variant: "danger",
          icon: "XIcon",
        });

        return
      }
      this.createEvent()
    },
    getRetryLimits(){
      return new EnumRetryLimits().items
    },
    createEvent(){
      this.modalInfo.loading = true
  
      const data = {
        description: '',
        identifier: this.modalInfo.newEventName,
        retry_limit: {
          id: this.modalInfo.newEventData.retryLimitItem.id
        },
        maxRetry: this.modalInfo.newEventData.maxRetry,
        object: this.modalInfo.newEventData.object
      }

      this.$store
        .dispatch("addEvent", {
          transmissionID: this.$route.params.transmissionID,
          data: data
        })
        .then((resp) => {
          makeToast({
            title: 'Success!',
            text: `Event ${this.modalInfo.newEventName} created successfully`,
            variant: "success",
            icon: "CheckIcon",
          });
          this.$emit('eventCreated', resp.data)
          this.modalInfo.loading = false
          this.$nextTick(()=>{
            this.$refs['modal-create-event'].hide()
          })
        })
        .catch((err) => {
          makeToast({
            title: 'Error!',
            text: `Something went wrong white creating the Event`,
            variant: "danger",
            icon: "XIcon",
          });
          console.error(err)
        })
        .finally(()=>{
          this.modalInfo.loading = false
        })    
    },
    
    },
  }
</script>

<style lang="scss" scoped>

</style>